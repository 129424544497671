<template>
  <div class="merchantForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="商家昵称"
        :rules="[
          { required: false, message: '请输入商家昵称', trigger: 'blur' },
        ]"
        prop="nickName"
      >
        <v-input
          disabled
          placeholder="请输入商家昵称"
          v-model="form.nickName"
          :width="250"
          :maxlength="10000"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="商家手机号"
        :rules="[
          { required: false, message: '请输入商家手机号', trigger: 'blur' },
        ]"
        prop="mobileNum"
      >
        <v-input
          disabled
          placeholder="请输入商家手机号"
          v-model="form.mobileNum"
          :width="250"
          :maxlength="10000"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="所属区域"
        :rules="[
          { required: false, message: '请输入所属区域', trigger: 'blur' },
        ]"
        prop="houseArea"
      >
        <v-input
          disabled
          placeholder="请输入所属区域"
          v-model="form.houseArea"
          :width="250"
          :maxlength="10000"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="商户类型"
        :rules="[{ required: false, message: '请商户类型', trigger: 'blur' }]"
        prop="houseArea"
      >
        {{ businessTypeopsMap[form.businessType] }}
      </el-form-item>
      <el-form-item
        label="商户营业执照"
        v-if="form.businessType == 1"
        :rules="[{ required: false, message: '商户营业执照', trigger: 'blur' }]"
        prop="imageUrl"
      >
        <div v-if="form.businessLicense">
          <div
            class="thumbImg"
            v-for="(item, index) in form.businessLicense.split(',')"
            :key="index"
          >
            <v-upload :disabled="true" :limit="1" :imgUrls.sync="item" />
          </div>
        </div>
      </el-form-item>
      <template v-if="form.businessType == 2">
        <el-form-item
          label="真实姓名"
          :rules="[{ required: false, message: '真实姓名', trigger: 'blur' }]"
          prop="realName"
        >
          <v-input
            disabled
            placeholder="真实姓名"
            v-model="form.realName"
            :width="250"
            :maxlength="10000"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="证件类型"
          :rules="[{ required: false, message: '证件类型', trigger: 'blur' }]"
          prop="idCardType"
        >
          {{ form.idCardType }}
        </el-form-item>
        <el-form-item
          label="证件号码"
          :rules="[{ required: false, message: '证件号码', trigger: 'blur' }]"
          prop="idCardNum"
        >
          {{ form.idCardNum }}
        </el-form-item>
        <el-form-item
          label="证件照"
          :rules="[{ required: false, message: '证件照', trigger: 'blur' }]"
        >
          <div class="allIdentyImg">
            <div class="identyImg">
              <v-upload
                :disabled="true"
                :limit="1"
                :imgUrls.sync="form.idCardPhotoFront"
              />
            </div>
            <div class="identyImg">
              <v-upload
                :disabled="true"
                :limit="1"
                :imgUrls.sync="form.idCardPhotoBack"
              />
            </div>
          </div>
        </el-form-item>
      </template>
      <el-form-item
        label="商户名称"
        :rules="[
          { required: false, message: '请输入商户名称', trigger: 'blur' },
        ]"
        prop="title"
      >
        <v-input
          disabled
          placeholder="商户名称"
          v-model="form.title"
          :width="250"
          :maxlength="10000"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="经营类型"
        :rules="[{ required: false, message: '经营类型', trigger: 'blur' }]"
        prop="manageType"
      >
        {{ manageTypeopsMap[form.manageType] }}
      </el-form-item>
      <el-form-item
        label="商户详细地址"
        :rules="[{ required: false, message: '商户详细地址', trigger: 'blur' }]"
        prop="detailedAddress"
      >
        <v-input
          disabled
          type="textarea"
          placeholder="商户详细地址"
          v-model="form.detailedAddress"
          :width="250"
          :maxlength="10000"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="商户营业时间"
        :rules="[{ required: false, message: '商户营业时间', trigger: 'blur' }]"
        prop="openingHours"
      >
        {{ form.openingHours }}
      </el-form-item>
      <el-form-item
        label="申请时间"
        :rules="[{ required: false, message: '申请时间', trigger: 'blur' }]"
        prop="insertTime"
      >
        {{ form.insertTime }}
      </el-form-item>
      <el-form-item
        label="商户状态"
        :rules="[{ required: false, message: '商户状态', trigger: 'blur' }]"
        prop="status"
      >
        {{ merchantStatusMap[form.status] }}
      </el-form-item>
      <el-form-item
        v-if="form.status == 0"
        label="审核"
        :rules="[{ required: true, message: '审核状态', trigger: 'blur' }]"
        prop="oStatus"
      >
        <v-select :options="handleStatus" v-model="form.oStatus" />
      </el-form-item>
      <template #saveBeforeSlot
        ><v-button text="保存" @click="save"> </v-button>
      </template>
    </form-panel>
  </div>
</template>

<script>
import { merchantDetailUrl, getShopperListUrl, handleMerchantUrl } from "./api";
import {
  businessTypeopsMap,
  manageTypeopsMap,
  handleStatusMap,
  handleStatus,
  merchantStatusMap,
} from "./map";

export default {
  name: "merchantForm",
  data() {
    return {
      merchantStatusMap,
      handleStatus,
      manageTypeopsMap,
      businessTypeopsMap,
      submitConfig: {
        queryUrl: merchantDetailUrl,
        submitUrl: "",
      },
      form: {
        id: null,
        nickName: "",
        mobileNum: "",
        houseArea: "",
        businessType: "",
        businessLicense: "",
        title: "",
        manageType: "",
        detailedAddress: "",
        openingHours: "",
        insertTime: "",
        realName: "",
        idCardType: "",
        idCardNum: "",
        idCardPhotoFront: "",
        idCardPhotoBack: "",
        status: "",
        auditStatus: "",
        oStatus: 1,
      },
    };
  },
  mounted() {
    const { id } = this.$route.query;

    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    async save() {
      let res = await this.$axios.post(
        `${handleMerchantUrl}?id=${this.form.id}&status=${this.form.oStatus}`
      );
      if (res.code === 200) {
        this.$message.success("保存成功");
        this.$router.go(-1);
      }
    },
    getShopperList() {
      this.shopperList = [];
      this.$axios.get(`${getShopperListUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.title;
            item.value = item.id;
            this.shopperList.push(item);
          });
        } else {
        }
      });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.form.oStatus = 1;
    },
    submitBefore() {
      console.log(this.form.oStatus, "==");
      return {
        id: this.form.id,
        status: this.form.oStatus,
      };
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.merchantForm {
  box-sizing: border-box;
  height: 100%;
  .thumbImg {
    display: flex;
    img {
      width: 100px;
      margin: 0 10px;
    }
  }
  .allIdentyImg {
    display: flex;
  }
}
</style>

